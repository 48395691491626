import Aos from "aos";
import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const equipmentData = [
  {
    id: 1,
    image: require('../src/Images/img1.jpg'),
    name: "Equipment 1",
    description: "Description for equipment 1.",
  },
  {
    id: 2,
    image: require('../src/Images/img2.jpg'),
    name: "Equipment 2",
    description: "Description for equipment 2.",
  },
  {
    id: 3,
    image: require('../src/Images/img3.jpg'),
    name: "Equipment 3",
    description: "Description for equipment 3.",
  },
  {
    id: 4,
    image: require('../src/Images/img4.jpg'),
    name: "Equipment 4",
    description: "Description for equipment 4.",
  },
  {
    id: 5,
    image: require('../src/Images/img5.jpg'),
    name: "Equipment 5",
    description: "Description for equipment 5.",
  },
  {
    id: 6,
    image: require('../src/Images/img6.jpg'),
    name: "Equipment 6",
    description: "Description for equipment 6.",
  },
];

export default function Equipments() {

  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <>
      <Container>
      < h4 className="text-center mt-5 mb-4"> Rental Equipments</h4>
      <Row >
        {equipmentData.map((equipment) => (
          <Col md={4} key={equipment.id} className="my-3 text-center" >
            <Card className="shadow image-zoom" 
            data-aos="fade-down" 
            data-aos-easing="linear"
             data-aos-duration="1000"
             as={Link}  to="/rentals">
              <Card.Img variant="top" src={equipment.image} />
              <Card.Body>
                {/* <Card.Title>{equipment.name}</Card.Title> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
        </Container>

    </>
  );
}
