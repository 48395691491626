import React, { useEffect } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import NavMenu from "./NavMenu";
import about from "../src/Images/abt1.jpg";
import slide1 from "../src/Images/s-1.jpg";
import slide2 from "../src/Images/s-2.jpg";
import slide3 from "../src/Images/s-3.jpg";
import { FiFilm, FiMapPin } from "react-icons/fi";
import { FiPhoneCall } from "react-icons/fi";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import Footer from "./Footer";
import Equipments from "./Equipments";
import Counting from "./Counting";
import { RiMovie2Line } from "react-icons/ri";
import { BiCameraMovie, BiMoviePlay } from "react-icons/bi";
import { PiFilmSlateLight } from "react-icons/pi";
import AOS from "aos";
import "aos/dist/aos.css";
import { HiOutlineMail } from "react-icons/hi";
import { GiFilmSpool } from "react-icons/gi";
import { ImFilm } from "react-icons/im";

export default function Home() {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavMenu />
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100 kenburns-top"
            src={slide1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 kenburns-top"
            src={slide2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 kenburns-top"
            src={slide3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>

      <div className="vibrate-1">
        <h3 className="text-center text-secondary mt-5">"Your Vision, Our Gear, Cinematic Excellence."</h3>
      </div>

      <Card className="my-5"
       data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000">
        <Row className="d-flex text-secondary text-center my-4 ">
          <Col lg={2} md="4" sm={2} xs={2} className="heartbeat" >
            <RiMovie2Line size={50} />
          </Col>
          <Col lg={2} md="4"  sm={2} xs={2} className="heartbeat" >
            <ImFilm size={50} />
          </Col>
          <Col lg={2} md="4" sm={2}  xs={2} className="heartbeat">
            <GiFilmSpool size={50} />
          </Col>
          <Col lg={2} md="4" sm={2} xs={2} className="heartbeat">
            <BiCameraMovie size={50} />
          </Col>
          <Col lg={2} md="4" sm={2}  xs={2} className="heartbeat">
            <PiFilmSlateLight size={50} />
          </Col>
          <Col lg={2} md="4" sm={2} xs={2} className="heartbeat">
            <FiFilm size={50} />
          </Col>
        </Row>
      </Card>

      <Card className="text-center mt-5  bg-warning fs-5 text-focus-in card-with-background"
       data-aos="fade-down"
       data-aos-easing="linear"
       data-aos-duration="1000">
        <Card.Body>
          <Card.Title><h3 className=''> <BiMoviePlay/> Mysuru Cine Service</h3></Card.Title>
        
          <Card.Text>
            <div className="d-flex gap-5 justify-content-center my-3">
            
              <div>
                <FiPhoneCall /> +91 97408 08996
              </div>
              <div>
                <TbDeviceLandlinePhone /> 0821-4263868
              </div>
            </div>
            <div>
              <HiOutlineMail /> mysurucineservice9771@gmail.com
            </div>
            <div className="my-3">
              <FiMapPin /> #R-112, 8th Main, Hebbal Industrial Area, Hebbal,
              Mysuru.
            </div>
          </Card.Text>
        </Card.Body>
      </Card>

      <Equipments />

      <Container
        className="d-flex justify-content-center mt-5"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <Card className="mb-3 shadow" style={{ maxWidth: "1000px" }}>
          <Row>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Card.Img
                src={about}
                className="img-fluid rounded-start "
                alt="..."
              />
            </Col>
            <Col lg={8} md={6} sm={12} xs={12}>
              <Card.Body>
                <Card.Title>Mysuru Cine Services</Card.Title>
                <Card.Text className="justify">
                  Mysuru a known for its imbibed heritage situated in the midest
                  of Natural Beauty. The peace loving citizens are so
                  co-operative and extend their helping hands in ventures. Their
                  hospitality attracts several people of different fields to
                  undertake very many works of social attraction. Cine field is
                  one of the most attractive media in these days. Cine field is
                  one of the most attractive media in these days. Mysuru an
                  heritage city - in and around several spots of Cine
                  Production. The International palace and other palaces like
                  Lalitha Mahal, Aloka, the deity of the Chamundeshwari Temple
                  and Tripurasundara Devi Temple of Uttarahalli are known for
                  their art and Architecture. Industrial as well as pilgrimage
                  center Nanjangudu, where the Srikanteshwara Temple is famous
                  for its artful construction. Quite near Gopalaswamy Temple in
                  the middle of forest always covered by snow and fag. K R Sagar
                  reservoir, Brindavan Garden, Rameswa Temple of age old
                  Architecture, Kuntibetta, Tonnurukere, Melkote Cheluva
                  Narayana Temple in Pandavapura Taluk and well known Historical
                  place Srirangapatna where Ranganatha Swamy Temple,
                  Tippusultan's Palace, Gumbaz, Karigatta are well know
                  including Pakshidhama (Bird Santury), Balamuri & Mahadevapura
                  are well known spots for cine production. Kaveri river and
                  Kabani River are also provide attractive spots for shooting.
                  In the city of Mysuru we set up an Outdoor Unit and Green Mat
                  Studio by name Mysuru Cine Service for which we supply
                  whatever the Tools and equipments, Generators including
                  manpower too as per your needs. The different types of lights,
                  cameras as per requirement supplied to the shooting spots at
                  reasonable rent.
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Container>
      <Counting />
      <Footer />
    </div>
  );
}
