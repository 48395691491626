import React, { useEffect } from 'react';
import { Container, Card, Table,  } from 'react-bootstrap';
import NavMenu from './NavMenu';
import Footer from './Footer';
import Aos from 'aos';

export default function EquipmentList() {
  const equipmentList = [
    { id: 1, name: '4K Par light', quantity: 2 },
    { id: 2, name: '1.8 K Par light', quantity: 2 },
    { id: 3, name: '4\'- 4 Bank light', quantity: 6 },
    { id: 4, name: '4\'- 4 Bank light (LED)', quantity: 2 },
    { id: 5, name: '4\'- 8 Bank light', quantity: 1 },
    { id: 6, name: '4\'- 2 Bank light', quantity: 2 },
    { id: 7, name: '2\'- 4 Bank Cool Light', quantity: 10 },
    { id: 8, name: 'Baby light', quantity: 19 },
    { id: 9, name: 'Multi - 10 light', quantity: 12 },
    { id: 10, name: 'Multi - 20 light', quantity: 12 },
    { id: 11, name: 'Junior light', quantity: 4 },
    { id: 12, name: 'Senior light', quantity: 4 },
    { id: 15, name: '2K Softy', quantity: 2 },
    { id: 17, name: 'Dino Light 9 Bank', quantity: 2 },
    { id: 18, name: 'Mini "C" Stand', quantity: 5 },
    { id: 19, name: 'Mini Par Stand', quantity: 2 },
    { id: 20, name: 'Cutter Stand (with Round Base)', quantity: 10 },
    { id: 21, name: 'Stand Green Paint heavy', quantity: 10 },
    { id: 22, name: 'Stand Green Paint normal', quantity: 10 },
    { id: 23, name: 'Regular Stand', quantity: 5 },
    { id: 24, name: 'Par Stand', quantity: 4 },
    { id: 25, name: 'C stand', quantity: 24 },
    { id: 26, name: 'Cardless Mike', quantity: 1 },
    { id: 24, name: 'Propellor', quantity: '1 set' },
    { id: 25, name: 'Rain effect', quantity: '1 set' },
    { id: 26, name: '10\' crane set up', quantity: '1 set' },
    { id: 27, name: '20\' crane set up', quantity: '1 set' },
    { id: 28, name: '40\' Crane set up', quantity: '1 set' },
    { id: 29, name: 'Trolley track set up', quantity: '2 sets' },
    { id: 30, name: 'Stool set up', quantity: '4 sets' },
    { id: 31, name: 'Reflector 4\' X 4\'', quantity: '10 Nos.' },
    { id: 32, name: '4\'/4\' Mirror', quantity: '4 Nos.' },
    { id: 33, name: '2\'/2\' Mirror', quantity: '4 Nos.' },
    { id: 34, name: '6\'/6\' Skimmer', quantity: '1 No.' },
    { id: 35, name: '8\'/8\' Skimmer', quantity: '1 No.' },
    { id: 36, name: '10\'/10\' Skimmer', quantity: '4 Nos.' },
    { id: 37, name: '12\'/12\' Skimmer', quantity: '1 No.' },
    { id: 38, name: '4\'/4\' Cutter frame (without paper)', quantity: '6 Nos.' },
    { id: 39, name: '4\'/4\' Cutter Frame (MS Pipe)', quantity: '13 Nos.' },
    { id: 40, name: '3\'/3\' Cutter frame (without paper)', quantity: '6 Nos.' },
    { id: 41, name: '2\'/4\' Cutter frame (without paper)', quantity: '6 Nos.' },
    { id: 42, name: '2\'/4\' Cutter Frame (Iron Rod)', quantity: '2 Nos.' },
    { id: 43, name: '2\'/2\' Cutter frame (without paper)', quantity: '8 Nos.' },
    { id: 44, name: '1\'/3\' Cutter frame (without paper)', quantity: '2 Nos.' },
    { id: 45, name: '1\'/2\' Cutter frame (without paper)', quantity: '2 Nos.' },
    { id: 46, name: 'Fighting bed', quantity: '10 Nos.' },
    { id: 47, name: 'King Pole', quantity: '1 SET' },
    { id: 48, name: 'Honda Generator 6.5 KV', quantity: '1 No.' },
    { id: 49, name: 'Honda Generator 7 KV', quantity: '1 No.' },
    { id: 50, name: 'Canter Vehicle', quantity: '1 No.' },
    { id: 51, name: 'Maruthi Omni', quantity: '1 No.' },
    { id: 52, name: 'Tata ACE Super', quantity: '1 No.' },
    { id: 53, name: 'Red Epic Camera set up with CP2 lens & HR Zoom', quantity: '' },
    { id: 54, name: 'Red Epic Camera set up with ultra prime & Alura Zoom', quantity: '' },
    { id: 55, name: 'Sony camera set up A7S2 with sony lens', quantity: '' },
    { id: 56, name: 'Sony camera set up A7S2 with CP2 lens', quantity: '' }
  ];

  
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);

  }, [])

  return (
    <>
    <NavMenu/>
    <Container >
        <h4 className='text-center my-4'>List of Equipment's</h4>
       <Card className="mb-3 shadow  w-75 mx-auto"  data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        <Table bordered >
          <thead>
            <tr className='text-center'>
              <th>Sl.No.</th>
              <th>Particulars</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {equipmentList.map((equipment, index) => (
              <tr key={equipment.id}>
                <td className='text-center'>{index + 1}</td>
                <td>{equipment.name}</td>
                <td className='text-center'>{equipment.quantity}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
    <Footer/>
    </>
  );
}


