import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { FiMapPin, FiPhoneCall } from "react-icons/fi";
import { TbDeviceLandlinePhone, TbListDetails } from "react-icons/tb";
import { BsCameraReels } from "react-icons/bs";
import { AiOutlineArrowRight, AiOutlineHome, AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BiMoviePlay } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";


function NavMenu() {
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary shadow">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <BiMoviePlay size={25} /> Mysuru Cine Service
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" className="nav-link">
                <AiOutlineHome size={20} /> Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="nav-link">
                <TbListDetails size={20} /> About
              </Nav.Link>
              <Nav.Link as={Link} to="/rentals" className="nav-link">
                <BsCameraReels /> Rentals
              </Nav.Link>
              <Nav.Link as={Link} to="/list" className="nav-link">
                <AiOutlineUnorderedList /> Equipments List
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" className="nav-link">
                <FiMapPin /> Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div class="bg-dark text-light">
  <div class="text-focus-in">
    <div class="row p-3 d-flex text-center ">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="hover-text-warning">
          Contact Information<AiOutlineArrowRight/>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="hover-text-warning">
          <HiOutlineMail /> mysurucineservice9771@gmail.com
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="hover-text-warning">
          <FiPhoneCall /> +91 97408 08996
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="hover-text-warning">
          <TbDeviceLandlinePhone /> 0821-4263868
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default NavMenu;
