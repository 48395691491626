import React, { useState, useEffect } from 'react';
import {  Row, Col } from 'react-bootstrap';
import { PiUsersThreeBold } from 'react-icons/pi';
import { BiCameraMovie, BiMoviePlay } from 'react-icons/bi';
import { RiMovieLine } from 'react-icons/ri';

const CountAnimation = ({ count, duration }) => {
  const [animatedCount, setAnimatedCount] = useState(0);

  useEffect(() => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;
      const increment = Math.ceil((count / duration) * progress);
      if (increment < count) {
        setAnimatedCount(increment);
        window.requestAnimationFrame(step);
      } else {
        setAnimatedCount(count);
      }
    };
    window.requestAnimationFrame(step);
  }, [count, duration]);

  return <span>{animatedCount}</span>;
};

const Counting = () => {
  const [movieCount] = useState(100);
  const [serialCount] = useState(500);
  const [eqpCount] = useState(500);
  const [CrewCount] = useState(100);

  return (
    <div className='text-dark' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
      <Row className="mt-4">
        <Col md={3} className="text-center">
          <h6 className="text-warning">Movies</h6>
          <p className="display-6">
            <BiMoviePlay className="count-icon" />
            <CountAnimation count={movieCount} duration={2500} />+
          </p>
        </Col>
        <Col md={3} className="text-center">
          <h6 className="text-warning">TV SERIALS</h6>
          <p className="display-6 ">
            <RiMovieLine className="count-icon" />
            <CountAnimation count={serialCount} duration={3000} />+
          </p>
        </Col>
        <Col md={3} className="text-center">
          <h6 className="text-warning">NO. OF EQUIPMENTS</h6>
          <p className="display-6">
            <BiCameraMovie className="count-icon" />
             <CountAnimation count={eqpCount} duration={4500} />+
          </p>
        </Col>
        <Col md={3} className="text-center">
          <h6 className="text-warning">Crew</h6>
          <p className="display-6 ">
            <PiUsersThreeBold className="count-icon" />
            <CountAnimation count={CrewCount}  duration={4000} />+
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Counting;
