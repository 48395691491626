import React from "react";
import { BiMoviePlay } from "react-icons/bi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h3 className='text-warning'> <BiMoviePlay/> Mysuru Cine Service</h3>
            <h5>Film Equipment Rental</h5>
            <p>Quality equipment for your projects.</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h4>Contact Info</h4>
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-map-marker-alt"></i> #R-112, 8th Main, Hebbal Industrial Area, Hebbal, Mysuru.
              </li>
              <br></br>
              <li>
                <i className="fas fa-phone"></i>  0821-4263868 / +91 97408 08996
              </li>
              <li>
                <i className="fas fa-envelope"></i>   mysurucineservice9771@gmail.com
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <Link className="text-warning" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="text-warning" to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link className="text-warning"  to="/rentals">
                  Equipments Rentals
                </Link>
              </li>
              <li>
                <Link className="text-warning" to="/list" >
                  Equipments List
                </Link>
              </li>
              <li>
                <Link className="text-warning" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center text-warning mt-4">
        <p>
          &copy; {new Date().getFullYear()} Torus Solutions. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
