import React, { useEffect } from "react";
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import { FiMapPin, FiPhoneCall } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { Card, Col, Row } from "react-bootstrap";
import { BiMoviePlay } from "react-icons/bi";
import Aos from "aos";

export default function Contact() {

  
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);

  }, [])

  return (
    <>
      <NavMenu />
      <Row>
        <Col  sm={12} md={12} lg={12} >
        <Card className="text-center my-5 w-80 mx-auto fs-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        <Card.Body>
          <Card.Title>Contact Information:</Card.Title>
          <Card.Text>
          <h3 className='mt-3'> <BiMoviePlay/> Mysuru Cine Service</h3>
            <div className="d-flex gap-5 justify-content-center my-3 text-secondary">
              <div >
                <FiPhoneCall /> +91 97408 08996
              </div>
              <div>
                <TbDeviceLandlinePhone /> 0821-4263868
              </div>
             
            </div>
            <div className="text-secondary">
                <HiOutlineMail /> mysurucineservice9771@gmail.com
              </div>
            <div className="my-3 text-secondary">
              <FiMapPin /> #R-112, 8th Main, Hebbal Industrial Area, Hebbal,
              Mysuru.
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col lg={10} md={8} sm={6} className="d-flex justify-content-center" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="2000">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3897.335390847362!2d76.61720414747256!3d12.36045192235822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf7b2148300e71%3A0x7d08aa0376ad9a4a!2sMysuru%20cine%20services!5e0!3m2!1sen!2sin!4v1697000721837!5m2!1sen!2sin"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
