import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import './Rental.css'
import Aos from "aos";

const equipmentData = [
  {
    id: 1,
    image: require('../src/Images/img1.jpg'),
  },
  {
    id: 2,
    image: require('../src/Images/img2.jpg'),
  },
  {
    id: 3,
    image: require('../src/Images/img3.jpg'),
  },
  {
    id: 4,
    image: require('../src/Images/img4.jpg'),
  },
  {
    id: 5,
    image: require('../src/Images/img5.jpg'),
  },
  {
    id: 6,
    image: require('../src/Images/img6.jpg'),
  },
  {
    id: 7,
    image: require('../src/Images/img7.jpg'),
  },
  {
    id: 41,
    image: require('../src/Images/p1.jpg'),
  },
  {
    id: 41,
    image: require(`../src/Images/p2.jpg`)
},
{
    id: 42,
    image: require(`../src/Images/p3.jpg`)
},
{
    id: 43,
    image: require(`../src/Images/p4.jpg`)
},
{
    id: 44,
    image: require(`../src/Images/p5.jpg`)
},
{
    id: 45,
    image: require(`../src/Images/p6.jpg`)
},
{
    id: 47,
    image: require(`../src/Images/p8.jpg`)
},
{
    id: 48,
    image: require(`../src/Images/p9.jpg`)
},
{
    id: 49,
    image: require(`../src/Images/p10.jpg`)
},


{
    id: 52,
    image: require(`../src/Images/p13.jpg`)
},

{
    id: 54,
    image: require(`../src/Images/p15.jpg`)
},
{
    id: 55,
    image: require(`../src/Images/p16.jpg`)
},
{
    id: 56,
    image: require(`../src/Images/p17.jpg`)
},
{
    id: 57,
    image: require(`../src/Images/p18.jpg`)
},
{
    id: 58,
    image: require(`../src/Images/p19.jpg`)
},
{
    id: 59,
    image: require(`../src/Images/p20.jpg`)
},
{
    id: 60,
    image: require(`../src/Images/p21.jpg`)
},

{
    id: 64,
    image: require(`../src/Images/p25.jpg`)
},

{
    id: 66,
    image: require(`../src/Images/p27.jpg`)
},
{
    id: 67,
    image: require(`../src/Images/p28.jpg`)
},
{
    id: 68,
    image: require(`../src/Images/p29.jpg`)
},
{
    id: 69,
    image: require(`../src/Images/p30.jpg`)
},
{
    id: 70,
    image: require(`../src/Images/p31.jpg`)
},
{
    id: 18,
    image: require('../src/Images/27.png'),
  },
  {
    id: 21,
    image: require('../src/Images/32.png'),
  },
  {
    id: 23,
    image: require('../src/Images/35.png'),
  },
  {
    id: 25,
    image: require('../src/Images/37.png'),
  },
  {
    id: 26,
    image: require('../src/Images/38.png'),
  },
  {
    id: 27,
    image: require('../src/Images/41.png'),
  },
  {
    id: 28,
    image: require('../src/Images/43.png'),
  },
  {
    id: 29,
    image: require('../src/Images/44.png'),
  },
  {
    id: 30,
    image: require('../src/Images/46.png'),
  },
  {
    id: 31,
    image: require('../src/Images/49.png'),
  },
  {
    id: 32,
    image: require('../src/Images/50.png'),
  },
  {
    id: 35,
    image: require('../src/Images/53.png'),
  },
  {
    id: 36,
    image: require('../src/Images/56.png'),
  },


  {
    id: 38,
    image: require('../src/Images/58.png'),
  },
  {
    id: 39,
    image: require('../src/Images/60.png'),
  },
  {
    id: 40,
    image: require('../src/Images/61.jpg'),
  },
  {
    id: 8,
    image: require('../src/Images/2.png'),
  },
  {
    id: 9,
    image: require('../src/Images/3.png'),
  },
  {
    id: 10,
    image: require('../src/Images/4.png'),
  },
  {
    id: 11,
    image: require('../src/Images/8.png'),
  },
  {
    id: 12,
    image: require('../src/Images/10.png'),
  },
  {
    id: 13,
    image: require('../src/Images/13.png'),
  },
  {
    id: 14,
    image: require('../src/Images/19.png'),
  },
  {
    id: 15,
    image: require('../src/Images/20.png'),
  },
  {
    id: 16,
    image: require('../src/Images/24.png'),
  },
  {
    id: 17,
    image: require('../src/Images/26.png'),
  },

  {
    id: 19,
    image: require('../src/Images/28.png'),
  },
  {
    id: 20,
    image: require('../src/Images/31.png'),
  },

  {
    id: 22,
    image: require('../src/Images/34.png'),
  },
 
  {
    id: 24,
    image: require('../src/Images/36.png'),
  },
  
  {
    id: 33,
    image: require('../src/Images/52.png'),
  },

  
  {
    id: 37,
    image: require('../src/Images/57.png'),
  },

  

];

export default function Rentals() {

    
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);

  }, [])

  return (
    <>
   <NavMenu />
      <Container>
        <h4 className="text-center mt-5 mb-4">Rental Equipments</h4>
        <Row>
          {equipmentData.map((equipment) => (
            <Col md={4} key={equipment.id} className="my-3 text-center">
              <Card data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                <div className="image-zoom">
                  <Card.Img variant="top" src={equipment.image} />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
