import React, { useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import about from '../src/Images/abt2.jpg';
import NavMenu from './NavMenu';
import Footer from './Footer';
import Aos from 'aos';

function AboutUs() {

  
  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);

  }, [])

  return (
    <>
      <NavMenu />
      <Container>
        <Card className="mb-3 shadow mt-4 mx-auto w-80" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
          <Row className="align-items-center">
            <Col lg={4} md={12}>
              <img src={about} className="img-fluid rounded-start w-100" alt="..." />
            </Col>
            <Col lg={6} md={12}>
              <Card.Body>
                <h3 style={{ textAlign: 'center' }}>ABOUT US</h3>
                <br />
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check justify"></i> We have a Company/Firm established in the year 2017 since then we have been providing services with all lights, tools, equipment, generator, and a green mat studio required for the production of cinemas, serials, ad shoots, short films, as well as any type of functions for lighting arrangements.
                  </li>
                  <br />
                  <li>
                    <i className="fa-solid fa-circle-check justify"></i> Our motto is to support and serve the cinema industry with the facilities we provide, which is why we ventured to establish this company/firm on a full scale.
                  </li>
                  <br />
                  <li>
                    <i className="fa-solid fa-circle-check justify"></i> We always welcome wholeheartedly all directors, producers, production managers, and those who are looking for the outdoor unit of film shooting.
                  </li>
                </ul>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Container>
      <Footer />
    </>
  );
}

export default AboutUs;
