
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import AboutUs from './About';
import Contact from './Contact';
import Rentals from './Rentals';
import EquipmentList from './EquipmentList';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="rentals" element={<Rentals />} />
        <Route path="contact" element={<Contact />} />
        <Route path="list" element={<EquipmentList />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
